<template>
  <div
    class="upload-wrapper"
    v-if="show"
    :class="showUpload ? 'show-upload' : 'hide-upload'"
  >
    <!-- v-show="showUpload()" -->
    <el-upload
      :action="action"
      :multiple="multiple"
      :file-list="fileList"
      :show-file-list="false"
      :on-success="handleUploadSuccess"
      :on-error="handleUploadError"
      :on-change="handleUploadChange"
      :on-exceed="handleExceed"
      name="multipartFile"
      :data="extra"
      :before-upload="beforeUpload"
      :headers="headers"
      :limit="limit"
    >
      <i class="el-icon-upload icon"></i>
      <!-- <van-icon name="back-top" class="icon" /> -->
    </el-upload>
    <div v-for="(item, index) in fileList" class="file-item" :key="index">
      <!-- <i class="el-icon-document icon book"></i> -->
      <van-icon name="orders-o" class="icon book" />
      <span class="file-name" @click="handleScan(item, index)">{{
        item.name
      }}</span>
      <!-- <i
        class="el-icon-upload-success el-icon-circle-check icon check-success"
      ></i> -->
      <van-icon name="certificate" class="icon check-success" />
      <!-- <i
        class="el-icon-close icon close"
        @click="handleRemove(item, index)"
      ></i> -->
      <van-icon
        class="icon close"
        name="close"
        @click="handleRemove(item, index)"
      />
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";

export default {
  data() {
    return {
      extra: {
        materialType: "1",
        returnUrl: "1",
      },
      headers: {},
      action: process.env.VUE_APP_BASE_URL + "/cdn/upload/uploadProcessFile",
      maxSize: 2, //m
      // accept: "image/jpg,image/png,image/jpeg",
      show: true,
    };
  },
  props: {
    limit: Number, //数量限制
    // action: String,
    multiple: { type: Boolean, default: false },
    value: { type: Array, default: () => [] }, //文件列表
  },
  computed: {
    fileList: {
      get() {
        return this.value || [];
      },
      set(list) {
        this.$emit("input", list);
      },
    },
    showUpload() {
      if (this.limit == 0) return true;
      if (this.fileList.length < this.limit) return true; //所选未传满显示
      return false;
    },
  },
  watch: {},
  mounted() {
    if (this.defaultFileList) {
      this.fileList = [...this.defaultFileList];
    }
    // 请求携带自定义token
    // 请求携带自定义token
    this.headers = {
      // Authorization: localStorage.getItem("Authorization_OA_H5"),
      Authorization:localStorage.getItem("Authorization_OA_H5"),

      "User-Source": "APP_OA",
    };
  },
  methods: {
    handleUploadChange(file, fileList) {},
    beforeUpload(file, fileList) {
      if (file.size > this.maxSize * 1024 * 1024) {
        this.$message(`文件大小不能超过${this.maxSize}M`);
        return false;
      }
      this.extra.materialName = file.name;
      this.extra.file = file;
    },

    handleExceed(file, fileList) {
      this.$message(`文件总数量不能超过 ${this.limit} 个`);
    },
    //删除
    handleRemove(item, index) {
      this.fileList.splice(index, 1);
    },
    //上传成功
    handleUploadSuccess(response, file, fileList) {
      // console.log(response);
      // console.log(fileList);

      let list = [];

      fileList.forEach((el) => {
        if (el.response) {
          if (el.response.code === 200) {
            list.push({
              name: el.name,
              url: el.response.data.fileUrl,
              objectKey: el.response.data.fileCode,
            });
          } else {
            if (el.response.code != 200) {
              this.$message("未审核通过，请检查");
            }
          }
        } else {
          list.push({
            ...el,
          });
        }
      });
      // console.log(list);

      this.fileList = list;

      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    handleUploadError(response) {
      this.$message({
        type: "error",
        message: "上传失败",
      });
    },
    handleScan(item, index) {
      if (item.url) {
        window.open(item.url);
      }

      // this.$router.push({
      //   path: "/fileScan",
      //   query: {
      //     url: encodeURIComponent(item.url),
      //     fileName: item.name,
      //   },
      // });
    },
  },
};
</script>
<style scoped lang="scss">
.upload-wrapper {
  .icon {
    font-size: 25px;
  }
  .file-item {
    cursor: pointer;
    font-size: 14px;
    line-height: 1.8;
    margin-top: 5px;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 4px;
    .book {
      margin-right: 7px;
      color: #909399;
      font-size: 14px;
    }
    .file-name {
      color: #606266;
      margin-right: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: color 0.3s;
      white-space: nowrap;
      flex: 1;
      width: 100%;
    }
    .check-success {
      font-size: 14px;
      color: #67c23a;
    }
    .close {
      font-size: 14px;
      color: #606266;
      display: none;
    }
  }
  .file-item:hover {
    background: #f8f8f8;
    .file-name {
      color: #0094ee;
    }
    .check-success {
      display: none;
    }
    .close {
      display: inline-block;
    }
  }
}
</style>
